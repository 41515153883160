<template>
  <div id="app">
    <HomePage />
  </div>
</template>

<script>
import HomePage from "@/pages/HomePage.vue";
export default {
  name: "app",
  components: { HomePage },
};
</script>

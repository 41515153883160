var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.nameInput)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.name.$model),expression:"$v.name.$model"}],staticClass:"common__input input",class:[
      {
        'input--error': _vm.nameFieldError,
      },
      { 'input--white': _vm.white },
      { 'common__input--small': _vm.small },
    ],attrs:{"type":"text","placeholder":"Ваше имя","name":"user_name"},domProps:{"value":(_vm.$v.name.$model)},on:{"blur":function($event){return _vm.onFieldBlur('name')},"focus":function($event){_vm.nameFieldFocused = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.name, "$model", $event.target.value)}}}):_vm._e(),(_vm.nameInput && _vm.nameFieldError)?_c('p',{staticClass:"common__error-text input__spase"},[_vm._v(" "+_vm._s(_vm.getNameErrorText(_vm.$v.name))+" ")]):_vm._e(),(_vm.contactInput)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$v.contact.$model),expression:"$v.contact.$model"}],staticClass:"common__input input",class:[
      { 'input--error': _vm.contactFieldError },
      { 'input--white': _vm.white },
      { 'common__input--small': _vm.small },
    ],attrs:{"type":"text","name":"user_contact","placeholder":"Ваш телефон/e-mail"},domProps:{"value":(_vm.$v.contact.$model)},on:{"blur":function($event){return _vm.onFieldBlur('contact')},"focus":function($event){_vm.contactFieldFocused = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.contact, "$model", $event.target.value)}}}):_vm._e(),(_vm.contactInput && _vm.contactFieldError)?_c('p',{staticClass:"common__error-text input__spase"},[_vm._v(" "+_vm._s(_vm.getContactErrorText(_vm.$v.contact))+" ")]):_vm._e(),(_vm.phoneInput)?_c('div',{staticClass:"phone-input__container",class:{ 'phone-input__container--small': _vm.small }},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:('+7(9##) ###-##-##'),expression:"'+7(9##) ###-##-##'"},{name:"model",rawName:"v-model",value:(_vm.$v.contact.$model),expression:"$v.contact.$model"}],staticClass:"common__input phone-input",class:[
        { 'phone-input--error': _vm.contactFieldError },
        { 'input--white': _vm.white },
        { 'common__input--small': _vm.small },
      ],attrs:{"type":"text","name":"user_contact","placeholder":"+7(9XX) XXX-XX-XX"},domProps:{"value":(_vm.$v.contact.$model)},on:{"blur":function($event){return _vm.onFieldBlur('contact')},"focus":function($event){_vm.contactFieldFocused = true},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.contact, "$model", $event.target.value)}}}),(_vm.contactFieldError)?_c('p',{staticClass:"common__error-text"},[_vm._v(" "+_vm._s(_vm.getContactErrorText(_vm.$v.contact))+" ")]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
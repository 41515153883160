<template>
  <p class="privacy">
    Сайт несёт информативный характер, и вся информация защищена авторскими
    правами. Администрация сайта с уважением относится к правам посетителей
    сайта. Мы безоговорочно признаём важность конфиденциальности личной
    информации посетителей нашего Сайта. Данная страница содержит сведения о
    том, какую информацию мы получаем и собираем, когда Вы пользуетесь сайтом.
    Мы надеемся, что эти сведения помогут Вам принимать осознанные решения в
    отношении предоставляемой нам личной информации
  </p>
</template>

<script>
export default {
  name: "PrivacyPolicyModalForm",
};
</script>
<style lang="scss" scoped>
.privacy {
}
</style>
